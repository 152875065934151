import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";

import { styled } from "@mui/material/styles";
import { IndexQuery } from "../../graphql-types";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PostCard from "../components/post-card";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";

import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";

import MdxLayout from "../components/mdx-layout";

export const pageQuery = graphql`
  query Index {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          body
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`;

const StyledPostCard = styled(PostCard)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

interface IndexPageProps {
  data: IndexQuery;
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const { edges: posts } = data.allMdx;

  return (
    <Layout pageTitle="Home">
      <div>
        {posts
          .filter((post) => {
            const title = post.node?.frontmatter?.title;
            if (title) {
              return title.length > 0;
            }
            return false;
          })
          .map(({ node: post }) => {
            return (
              <StyledPostCard
                key={post.id}
                title={
                  post.frontmatter?.path && (
                    <Link to={post.frontmatter?.path}>
                      {post.frontmatter?.title}
                    </Link>
                  )
                }
                date={post.frontmatter?.date}
              >
                {post.body && (
                  <MdxLayout>
                    <MDXRenderer>{post.body}</MDXRenderer>
                  </MdxLayout>
                )}
              </StyledPostCard>
            );
          })}
      </div>
      <Timeline>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              <FastfoodIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Eat</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <LaptopMacIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Code</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              <HotelIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Sleep</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              <RepeatIcon />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>Repeat</TimelineContent>
        </TimelineItem>
      </Timeline>
    </Layout>
  );
};

export default IndexPage;
